// Default
$light-theme: false;
$bg-color: #424242;
$pm-color: #318ed5;
$sd-color: #e26a6a;
$dk-color: #1d2127;
$pm-text-color: #ffffff;
$muted-color: mix(white, $bg-color, 70%);
$trans-time: 300ms;
$full-width: 100%;
$half-width: 50%;
$quarter-width: 25%;
$backdrop: rgba(0, 0, 0, 0.5);
$default-radius: 4px;

// CART STYLE
$cart_background: #f7f8f8;
$items_background: #f7f8f8;
$cart_title: 1.3em;

// CART STATUS
$default_color: #666766;
$toolbar_default: #cccccc;
$cart_preparing: #f79d34;
$cart_ready: #3bae70;
$cart_new: #dedede;
$cart_printed: #a4d9ef;
$cart_completed: #64c2ea;

$product-size: 10rem;
$product-color: #b7b7b7;
$product-radius: 4px;
$product-name-size: 9rem;

@if $light-theme {
  $bg-color: lighten($bg-color, 90%);
  $pm-text-color: darken($pm-text-color, 60%);
  $muted-color: lighten($muted-color, 60%);
}
