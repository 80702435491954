@import './common.scss';
@import './variables.scss';

#spar-cart {
  display: flex;
  flex-direction: column;
  height: 90vh; // check if 100vh is better for big screen

  #spar-cart-header {
    background-color: #ffffff;
    width: calc(100%) - 30px;

    #cart-top-btns-container {
      display: flex;
      // background-color: #0078af13;
      justify-content: space-between;
      padding-left: 15px;
    }

    .product-barcode {
      display: flex;
      align-items: center;
      font-size: 32px;
      font-weight: 800;
    }
  }

  .cart-items-container {
    width: 85%;
  }

  .cart-items-container-full {
    width: 100%;
  }

  .extra-amount {
    background-color: #f88b85 !important;
  }

  .exact-amount-check {
    background-color: #9cbc84 !important;
  }

  .full-width-title {
    width: auto !important;
  }

  #spar-cart-items-list {
    display: flex;
    justify-content: center;
    min-height: 50px;
    padding: 5px;
    overflow: auto;
    flex-flow: column;

    .carts-to-check-container {
      display: flex;

      .title-container {
        text-align: center;
        background-color: #89c3ed;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-right: 45px;
        margin-left: 45px;
        border-radius: 8px;
        color: #fff;
      }

      .original-cart-container {
        flex: 1;
        border-right: 2px solid #c3c3c3;
      }
      .checked-items-container {
        flex: 1;

        .finish-basket-check {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          gap: 20px;
          background-color: #89c3ed;
          border-radius: 8px;
          color: #fff;
          padding-right: 10px;

          h3 {
            flex: auto;
            text-align: center;
          }

          .items-amount {
            padding: 5px !important;
            border: 2px solid #f0f0f0;
            margin-left: 15px;
          }

          button {
            background-color: #f88b85 !important;
            color: #fff;
            min-height: 30px !important;
            float: right;
          }
        }
      }
    }

    .spar-cart-item-container {
      background-color: #ffffff;
      border-radius: 15px;
      border: #d6d6d6 solid 0.5rem;
      color: #000;
      .last-scanned-item {
        background-color: rgba(43, 119, 155, 0.25);
        border-radius: 7px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px,
          rgba(0, 0, 0, 0.23) 0px 3px 8px;
      }
      .spar-cart-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 25px 15px;

        .spar-image-title-container {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 25%;
          .spar-item-image {
            height: 65px;
            width: 65px;
            border-radius: 4px;
          }
          .spar-item-no-image {
            background-color: #858585;
            color: #ffffff;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .product-info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .spar-cart-item-title {
              font-weight: bold;
              font-size: 20px;
            }
            .spar-cart-item-extras {
              font-size: 14px;
              font-size: 1.1vw;
              opacity: 0.8;
              white-space: nowrap;
            }
            .price-container {
              white-space: nowrap;
            }
          }
        }
        .spar-item-price-action-container {
          display: flex;
          gap: 15px;
          font-weight: bold;
          align-items: center;
          width: 45%;
          justify-content: flex-end;
          font-size: 19px;

          .add-reduction {
            color: #858585;
            text-decoration: line-through;
            letter-spacing: 0.02em;
          }
          .spar-tem-dlt-icon {
            background-color: #f5f5f5;
          }
        }
        .spar-cart-add-remove-container {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: flex-start;
          .add-remove-btn-container {
            background-color: #0078af13;
          }
          .add-remove-btn-container-disabled {
            background-color: #f5f5f5;
          }
          .spar-cart-item-amount-container {
            border: 1px solid #dddddd;
            width: 20px;
            height: 20px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
          }
        }
        .quantity-buttons {
          width: 12%;
        }
      }
      .spar-loyalty-points-container {
        border-top: 2px solid #d6d6d6;
        margin-top: 10px;
        padding-top: 15px;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        color: #858585;
        font-size: 14px;
      }
      .spar-loyalty-points-container:first-child {
        font-weight: bold;
      }
    }
  }
  #spar-cart-footer {
    background-color: #ffffff;
    width: 100%;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    #customer-card-btn-container {
      width: 95%;

      .colored-txt-btn {
        border: 0;
        color: #0077af;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
      }
    }
    .cart-footer-sections {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      width: 95%;
      color: #000;
      #cart-points-text {
        padding-left: 15px;
        padding-top: 15px;
      }
      #cart-points-value {
        padding-right: 15px;
        padding-top: 15px;
        font-weight: bold;
      }
    }
  }
  .spar-cart-round-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
  }

  .product-container {
    width: 81%;
  }

  .no-barcode-container,
  .cart-container {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    background-color: lightsalmon;
    justify-content: space-between;
    background-color: #d6d6d6;

    .categories-container,
    .cart-items-container,
    .product-container {
      overflow: auto;
    }
  }
}

.kiosk-btn-container {
  border-radius: 4px;
  min-height: 0;
  padding: 30px;
  font-family: 'Source Sans Pro', sans-serif;

  &:disabled {
    background-color: #858585 !important;
    color: #fff;
  }
}

.dark-contained-btn {
  color: #ffffff;
  background-color: #ffa500;
  width: 20%;
}

.light-contained-btn {
  color: #0077af;
  background-color: #0078af13;
  min-width: 150px;
}

.asking-for-help-btn {
  color: #0077af;
  background-color: #0078af13;
  box-shadow: inset 1px 1px 6px #2b779bfd;
  min-width: 150px;
}

.transparent-contained-btn {
  color: #0077af;
  background-color: transparent;
}

.nix-msg {
  width: 30%;
  margin: 15px 0px 0px 300px;
  color: red;
  font-weight: 800;
}

.check-amounts-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  // padding: 0px 15px;
  gap: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  width: 40%;
  border: 2px solid rgba(0, 0, 0, 0.56);
  .text-align-center {
    text-align: center;
  }
}
