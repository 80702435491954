.selected-addon-tiles-list {
  position: absolute;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  background-color: #fff;
  width: 100%;
  // overflow: auto;
  .selected-addon-tile {
    border: 2px solid #ffa500;
    border-radius: 8px;
    padding: 5px;
  }
}
.addon-groups {
  margin-top: 50px;
  header {
    padding: 8px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .validate {
      font-size: 24px;
      font-weight: 600;

      .valid {
        color: greenyellow;
      }

      .invalid {
        color: red;
      }
    }
  }
}

.addon-selection {
  .title {
    flex: 1 !important;
    padding: 8px !important;
    cursor: pointer !important;
  }
}
