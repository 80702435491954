@import './variables.scss';

* {
  user-select: none;
  -moz-user-select: none;
}

@mixin default_box {
  background-color: $cart_background;
}

@mixin new_status_box {
  background-color: $cart_new;
}

@mixin preparing_status_box {
  background-color: $cart_preparing;
}

@mixin ready_status_box {
  background-color: $cart_ready;
}

@mixin completed_status_box {
  background-color: $cart_completed;
}

@mixin printed_status_box {
  background-color: $cart_printed;
}

body {
  background-color: $bg-color;
  color: $pm-text-color;
  transition: background-color 500ms ease, color 1000ms ease;
}

body.light {
  background-color: $cart_background;
  color: $dk-color;
}

.pointer {
  cursor: pointer;
}

.modal-actions {
  justify-content: space-around !important;

  button {
    width: 20% !important;
  }
}

.modal-body {
  overflow-y: auto !important;
}
