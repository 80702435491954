// VARS
@import './variables.scss';

button {
  min-height: 80px;
}

.screen-saver-on {
  height: 0;
  z-index: 0;
}

#app-container {
  display: flex;
  flex-direction: column;

  #children-container {
    flex: 'auto';
  }
}

// HEADER
header.app-header {
  min-height: 64px;
  // position: sticky;
  // top: 0;
  // z-index: 10;
  flex: 2;
  color: #ccc;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1em;
  align-items: center;
  text-align: center;
  padding: 5px;

  &.light {
    background-color: $toolbar_default;
    color: $dk-color;
  }

  .back-arrow {
    margin: 16px;
    font-size: 30px;
    cursor: pointer;
  }

  img.logo {
    width: $full-width;
    transition: all 1000ms ease;

    &.light {
      // filter: brightness(0);
      filter: grayscale(100%) invert(100%);
    }
  }

  .lang-selection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-end: -1;

    input {
      color: #000;
    }

    svg {
      grid-column: -1 / -2;
      justify-self: flex-end;
      margin: 16px;
      font-size: 30px;
      cursor: pointer;
    }

    .close {
      position: absolute;
      right: -12px;
      top: 20px;
    }
  }
  .ask-help {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-end: -1;

    .kiosk-btn-container {
      border-radius: 4px;
      padding: 2px;
      min-height: 0;
      padding: 20px;
      font-family: 'Source Sans Pro', sans-serif;

      &:disabled {
        background-color: #858585 !important;
        color: #fff;
      }
    }
  }

  .light-contained-btn {
    color: #0077af;
    background-color: #0078af13;
    min-width: 150px;
  }

  .asking-for-help-btn {
    color: #0077af;
    background-color: #0078af13;
    box-shadow: inset 1px 1px 6px #2b779bfd;
    min-width: 150px;
  }

  @media only screen and (max-width: 515px) {
    .search {
      display: none;
    }
  }
}

.arrow-back {
  cursor: pointer;
  padding: 14px 0 0 32px;
  width: 10%;

  img {
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: 0.02em;
  }
}

.lang-btn {
  display: flex;
  align-items: center;
  min-height: 60px;
  min-width: 75px;

  span {
    font-weight: 800;
    margin-left: 5%;
  }
}

.loader-container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 2;
  display: flex;

  .spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.admin-pin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 20px;
    font-weight: 600;
  }
}
