@import './variables.scss';

.landing-page {
  display: flex;
  justify-content: center;
}

.login-page {
  display: flex;
  justify-content: center;
}

.full-basket-check {
  text-align: center;
  background-color: #f1514a;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
}

.main-page {
  display: flex;
  flex-direction: column;

  .modal-title {
    text-align: center !important;
  }

  // TODO: For big screens, put cart at the right side (web pos like)
  // @media only screen and (max-width: 1024px) {
  //   background: #fff;
  // }
}

.receipt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 10%;

  .receipt-buttons {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-evenly;

    .full-button {
      width: 40%;
      background-color: #0077af;
      color: white;
      border-radius: 4px;
      font-size: 25px;
      font-weight: 800;

      &:disabled {
        background-color: #858585 !important;
        color: #fff;
      }
    }
    .short-button {
      width: 40%;
      background-color: #0078af13;
      color: #0077af;
      border-radius: 4px;
      font-size: 25px;
      font-weight: 800;

      &:disabled {
        background-color: #858585 !important;
        color: #fff;
      }
    }
  }

  .receipt-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 5%;

    h1 {
      color: #297d28;
    }

    .sub-text {
      color: #878787;
    }

    .main-text {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}

.welcome-page,
.end-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  img {
    width: 800px;
    height: 800px;
  }
}

.welcome-info,
.endpage-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 5%;

  .welcome-text-info {
    h3 {
      color: #0077af;
    }
  }

  .language-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    .language {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px;
      cursor: pointer;

      img {
        width: 56px;
        height: 40px;
      }
      span {
        margin-left: 5%;
        font-weight: 800;
      }
    }
  }

  .start-button {
    margin: 30px 0 30px 0;

    button {
      color: #ffffff;
      background-color: #0077af;
      width: 100%;
      font-weight: 800;
      font-size: 24px;
      cursor: pointer;
    }
  }
}
.show-arrow {
  h1 {
    text-align: center;
  }
  .arrow {
    text-align: center;
    margin: 8% 0;

    svg {
      font-size: 350px;
    }
  }
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
}

.checkout-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header {
    display: flex;
    flex-direction: row;
    margin: $cart_title / 2;

    span {
      font-size: 20;
      font-weight: 600;
    }

    :nth-child(1) {
      width: $product-size / 2;
      margin: 0 $default-radius * 2;
    }
    :nth-child(2) {
      flex: 2;
      margin-left: 16px;
    }
    :nth-child(3) {
      flex: 1;
      text-align: center;
    }
    :nth-child(4) {
      flex: 1;
      text-align: center;
    }
    :nth-child(5) {
      min-width: 60px;
    }
  }

  .items {
    flex: 1;
  }
}

.payment-page {
  .modal-content {
    height: 30% !important;
    border-radius: 25px !important;
    background-color: transparent !important;

    .modal-title {
      display: none !important;
    }

    .modal-body {
      height: 100% !important;
      max-height: none !important;
      overflow-y: hidden !important;
      overflow-x: hidden !important;
    }

    padding: 0 !important;
    margin: 0 !important;
  }
  .pay-image-modal {
    position: relative;
    text-align: center;
    font-size: 1.2rem;
    color: #fff;

    .pay-img-text-up {
      position: absolute;
      top: 45%;
      width: 100%;
    }

    .pay-img-text-down {
      position: absolute;
      bottom: 15%;
      width: 100%;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;

    .details-pay,
    .details-remaining {
      display: flex;
      flex-direction: row;
      padding: 16px;
      width: 100%;
      justify-content: space-between;
    }

    .details-hr {
      width: 100%;
      color: white;
    }

    h3 {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 0;
    }

    h5 {
      font-size: 24px;
      margin: 12px;
    }

    h6 {
      font-size: 14px;
      font-weight: 200;
      margin-top: 6px;
    }
  }
  .remaining {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    .details-remaining {
      display: flex;
      flex-direction: row;
      padding: 16px;
      width: 100%;
      justify-content: space-between;
    }
    .details-hr {
      width: 100%;
      color: white;
    }
  }
  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 32px;
  }
}

.settings-page {
  .section {
    margin: 15px;
    padding: 15px;
    border: 1px solid rgba(198, 198, 198, 0.071);
    border-radius: $default-radius;
    background-color: rgba(198, 198, 198, 0.071);
    -webkit-animation: slideIn 1.3s forwards;
    -moz-animation: slideIn 1.3s forwards;
    animation: slideIn 1.3s forwards;

    .section-title {
      text-align: center;
      span {
        font-size: 24px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  p {
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
  }

  .ad-preview {
    display: flex;
    justify-content: space-around;
    // margin-top: 30px;
    padding-top: 10px;
  }

  .video-container {
    box-shadow: -8px -8px 12px 0 rgba(0, 0, 0, 0.3),
      8px 8px 12px rgba(255, 255, 255, 0.25);
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    img {
      max-width: 150px;
      max-width: 150px;
      object-fit: scale-down;
      flex: auto;
      border: 2px solid #f0f0f0;
      border-radius: 8px;
      box-shadow: -4px -4px 8px 0 rgba(0, 0, 0, 0.3),
        4px 4px 8px rgba(255, 255, 255, 0.25);
    }
  }

  .pdf-preview {
    canvas {
      max-height: 300px;
      max-width: 200px;
    }
  }

  .setting-tabs {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 10px;
    .active-tab {
      border-width: 0px;
      background-color: #338ed5;
      color: #f0f0f0;
    }
    div {
      border-left: 3px solid #338ed5;
    }
    button {
      width: 48%;
      border: 4px solid #338ed5;
      background-color: #c4daeb;
    }
  }

  @-webkit-keyframes slideIn {
    0% {
      transform: translateX(-900px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes slideIn {
    0% {
      transform: translateX(-900px);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateX(-900px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
