.buttons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 32px;

  .language-tiles {
    background: linear-gradient(0deg, #f5fafc, #f5fafc), rgba(0, 119, 175, 0.04);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 60px;
    margin: 32px 0px;
    border-radius: 10px;
    cursor: pointer;
    color: black;

    .language-icon {
      width: 100%;
    }

    img,
    .icon {
      height: 120px;
      width: 120px;
    }
    .description {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #0077af;
    }
  }
}
