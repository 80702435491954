@import './variables.scss';

.product-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin: 13px 0 10px 10px;
}

.no-products {
  height: 100px;
  width: 200px;
  justify-content: center;
}
