@import './variables.scss';

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: $bg-color;
  box-shadow: 0px -5px 15px 2px rgba(0, 0, 0, 0.75);
}

.payment-tiles {
  background: linear-gradient(0deg, #f5fafc, #f5fafc), rgba(0, 119, 175, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 60px;
  margin: 32px 0px;
  border-radius: 10px;
  cursor: pointer;
  color: black;

  .payment-icon {
    width: 100%;

    img,
    .icon {
      height: 240px;
      width: 240px;
    }
  }

  .description {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    padding: 100px 0px;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #0077af;
  }
}

.payment-button {
  background-color: rgba($muted-color, 0.05);
  border-radius: $default-radius;
  margin: $cart_title;
  padding: $cart_title / 2;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: transform $trans-time, box-shadow $trans-time;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.12);

    .icon {
      filter: unset !important;
    }

    .description span {
      font-weight: 600;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  .icon {
    margin-left: $default-radius * 2;
    margin-right: $default-radius * 2;
    text-align: center;
    width: $product-name-size / 2;
    font-size: $product-name-size / 2;
    border-radius: $default-radius;
    filter: grayscale(1);
  }

  .description {
    flex: 1;

    span {
      font-size: $product-name-size / 4;
      transition: font-weight $trans-time;
    }
  }
}

.running-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .display-messages {
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .actions {
    text-align: right;
    margin: 12px 6px;
  }
}

.result-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .status {
    .icon {
      font-size: 8rem;

      &.ok {
        color: $pm-color;
      }

      &.fail {
        color: $sd-color;
      }
    }
  }

  .message {
    span {
      font-size: 2rem;
      font-weight: 600;
    }
  }
}
