@import './common.scss';
@import './variables.scss';

.product-title-wrapper {
  position: relative;

  > i {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    font-size: 1.8rem;
  }

  .product-box {
    min-width: 180px;
    // min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    justify-content: space-evenly;
    background-color: #ffffff;

    .product-img-small {
      width: 60px;
      height: 60px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        object-fit: contain;
      }
    }

    .product-img {
      width: 160px;
      height: 160px;

      img {
        width: 160px;
        height: 160px;
        border-radius: 8px;
        object-fit: contain;
      }
    }
    .product-title {
      width: 180px;
      color: #000000;
      word-break: keep-all;

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
      }
    }

    .product-price {
      width: 160px;
      height: 32px;
      text-align: right;

      span {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 32px;
        letter-spacing: 0.02em;
      }
    }
  }
}

.cart {
  flex: 1;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  background: $bg-color;
  z-index: 1;
  box-shadow: 0px -5px 15px 2px rgba(0, 0, 0, 0.75);

  .header {
    background: red;
  }

  .toolbar {
    background: blue;
  }

  .footer {
    background: green;
  }

  .simple-keyboard {
    max-width: 100%;
    color: black;
  }

  .content {
    display: flex;
    flex-direction: row;

    .items-list {
      flex: 3;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: 275px;
      overflow-y: scroll;

      .no-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        span {
          padding: 24px 36px;
        }
      }
    }
  }
}
